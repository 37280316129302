import customerAPI from './customerAPI.js';
import audienceAPI from './audienceAPI.js';
import templatesAPI from './templatesAPI.js';

const core = {
	customerAPI,
	audienceAPI,
	templatesAPI
}

export default core;
