// Initial declaration
const templatesAPI = {};

// Function setup
templatesAPI['GetOrSet'] = function (noError) {
	return new Promise((resolve, reject) => {
		// Get cached
		let output = api.get('templates');

		// Return if cached
		if (output)
			return resolve(output);

		// Get from database and cache
		api.service('widgets').find({ query: { public:true, $limit: 2000, $sort: { name: 1 } } })
			.then(widgets => {
				// Cache
				const templates = widgets.data.map( t => ({id:t._id,name:t.name,template:t.template}) );
				
				api.set('templates', templates);

				// Resolve
				resolve(templates)
			})
			.catch(err => {
				if (!noError)
					reject(err);
				else
					resolve(null);
			});
	});
}
templatesAPI['Get'] = function (noError) {
	return new Promise((resolve, reject) => {
		// Get from database and cache
		api.service('widgets').find({ query: { public:true, $limit: 2000, $sort: { name: 1 } } })
			.then(widgets => {
				// Cache
				const templates = widgets.data.map(t => ({id:t._id,name:t.name,template:t.template}));
				
				api.set('templates', templates);

				// Resolve
				resolve(templates)
			})
			.catch(err => {
				if (!noError)
					reject(err);
				else
					resolve(null);
			});
	});
}

// Export
export default templatesAPI;
