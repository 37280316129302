const riot = require('riot');
riot.tag2('modal', '<div if="{modal}" class="modal is-active"> <div class="modal-background" onclick="{modal.onclose}"></div> <div class="modal-content" style="width:auto"> <div class="box"> <h3 class="title is-5 has-text-primary">{modal.title}</h3> <section class="content"> {modal.content} </section> <div class="buttons is-centered"> <button each="{modal.buttons}" class="button is-{type}" onclick="{onclick}"> <span>{title}</span> <span if="{icon}" class="icon"><i class="fa fa-{icon}"></i></span> </button> </div> </div> </div> </div>', '', '', function(opts) {
    const self = this,
          callback = (res) => (e) => {
console.log('callback',res)
            if (self.modal.cb) self.modal.cb(res)
            self.modal = null
          }

    self.modal = null
    api.on('modal', (modal) => {
console.log('got modal',modal)
      if (modal.type=='confirm') {
        self.modal = {
          title: modal.title,
          content: modal.content,
          cb: modal.cb,
          onclose: callback(false),
          buttons: [
            {type:'primary',icon:'check',title:'OK',onclick:callback(true)},
            {type:'normal',icon:false,title:'Cancel',onclick:callback(false)}
          ]
        }
      } else if (modal.type=='alert') {
        self.modal = {
          title: modal.title,
          content: modal.content,
          onclose: callback(false),
          showclose:true,
          cb: modal.cb,
          buttons: [
            {type:'normal',icon:false,title:'Close',onclick:callback(false)}
          ]
        }
      }
      if (self.modal) self.update()
    })
});
