const riot = require('riot');
riot.tag2('toast', '<div class="message is-{type}" each="{toasts}"> <div class="message-header"> <p><span class="icon"><i class="fa fa-{icon}"></i></span> {title}</p> </div> <div if="{content}" class="message-body"> <small>{content}</small> </div> </div>', 'toast,[data-is="toast"]{ position:fixed; bottom:3em; left:0; right:0; text-align:center; z-index:999; display:flex; justify-content:center; flex-flow:column nowrap; } toast .message,[data-is="toast"] .message{ display:inline-block; margin:0 auto; }', '', function(opts) {
    const self = this,
          icons = {
            success: 'check',
            info: 'info-circle',
            warning: 'exclamation-circle',
            danger: 'ban'
          }

    self.toasts = []
    api.on('toast', (data)=>{
console.log('toast',data)
      data.icon = icons[data.type] || 'info'
      self.toasts.push(data)
      self.update()
      setTimeout( () => {
        self.toasts.splice(0,1)
        self.update()
      },5000)
    })
});
