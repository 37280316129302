const riot = require('riot');
riot.tag2('user', '<div class="card is-collapse"> <header class="card-header {has-background-light:expanded}" onclick="{toggleExpand}"> <p class="card-header-title" style="display:block;font-weight:normal;padding:0.25rem 0.5rem"> {email} <br> <small>{_id}</small> </p> <div class="card-header-icon" style="padding:1rem 0.5rem;"> <span>{roles.join(\', \')}</span> <a href="#" onclick="{toggleExpanded}" aria-label="more options"> <span class="icon"> <i class="fas fa-angle-down" aria-hidden="true"></i> </span> </a> </div> </header> <div if="{expanded}" class="card-content has-background-white-bis" style="margin-bottom:1rem"> <form> <div class="columns"> <div class="column"> <div class="field is-grouped"> <div class="control"> <label for="email" class="label">Email</label> <input class="input" type="text" riot-value="{email}" ref="email"> </div> <div class="control"> <label for="roles" class="label">Roles</label> <input class="input" type="text" disabled="{!isadmin}" riot-value="{roles && roles.join(\', \')}" ref="roles"> </div> <div class="field control"> <label for="name" class="label">Password</label> <input class="input" type="text" value="" ref="new_pass" placeholder="enter new password"> </div> </div> </div> </div> <div if="{isadmin}" class="field is-grouped is-grouped-centered"> <div class="control"> <button class="button is-info" onclick="{saveUser}">Save changes</button> </div><div class="control"> <button class="button is-light" onclick="{removeUser}">Remove user</button> </div> </div> </form> </div> </div>', '', '', function(opts) {
  var self = this

  self.isadmin = (api.get('user').roles || []).indexOf('admin')>-1

  this.toggleExpand = function(e) {
    e.preventDefault()
    e.stopPropagation()
    self.expanded = !self.expanded
  }.bind(this)

  this.removeUser = function(e) {
    e.preventDefault()
    api.service('users').remove(self._id)
    .then(r => {
      api.emit('toast',{type:'success',title:'User removed'})
      self.parent.users = self.parent.users.filter( u => u._id != self._id)
      self.parent.update()
    })
    .catch(e => {
      api.emit('toast',{type:'danger',title:'Error removing user',content:e})
    })
  }.bind(this)

  this.saveUser = function(e) {
    e.preventDefault()
    var email = self.refs.email.value,
        roles = self.refs.roles.value || '',
        new_pass = self.refs.new_pass.value
        usr = {}

    if (!email) return api.emit('toast',{type:'danger',title:'E-mail cannot be emtpy'})
    if (new_pass && new_pass.length<8) return api.emit('toast',{type:'danger',title:'Password must contain at least 8 characters'})

    usr.email = email
    if (new_pass) usr.password = new_pass
    if (self.isadmin) usr.roles = roles.split(/[,\s\n]+/g)

    api.service('users')
    .patch(e.item._id,usr)
    .then(r => {
      api.emit('toast',{type:'info',title:'User updated'})
      const id = self.parent.users.findIndex(d => d._id==e.item._id)
      c._id = e.item._id
      self.parent.users[id] = c
      self.parent.update()
    })
    .catch(e => {
      console.log('error patching campaign',e)
      self.error = e.message
      self.update()
    })
    console.log('saving',e)
  }.bind(this)
});

