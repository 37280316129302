const riot = require('riot');
import './user.tag'
riot.tag2('users', '<div class="level"> <div class="level-left"> <h1 class="title is-5 level-item">Users</h1> </div> <div if="{isadmin}" class="level-right"> <form onsubmit="{createCampaign}" class="level-item"> <div class="field is-grouped"> <div class="control" style="flex-shrink:1"> <input class="input" ref="new_mail" value="" placeholder="Enter user\'s email" type="email"> </div> <div class="control" style="flex-shrink:1"> <input class="input" type="password" ref="new_pass" value="" placeholder="Enter user\'s password"> </div> <div class="control"> <button class="button is-info" onclick="{createUser}">Add <span class="is-hidden-mobile">User</span></button> </div> </div> </form> </div> </div> <virtual data-is="user" each="{users}" expand="{expanded}"></virtual>', '', '', function(opts) {

  var self = this,
      cid = opts.id

  self.users = []
  self.isadmin = (api.get('user').roles || []).indexOf('admin')>-1

  this.createUser = function(e) {
    e.preventDefault()
    let email = self.refs.new_mail.value,
        passw = self.refs.new_pass.value,
        user = api.get('user')

    if (!email) return api.emit('toast',{type:'danger',title:'E-mail cannot be emtpy'})
    if (!passw || passw.length<8) return api.emit('toast',{type:'danger',title:'Password must contain at least 8 characters'})

    api.service('users')
    .create({
      email: email,
      password: passw,
      owner: user._id
    })
    .then(user => {
      console.log('created user',user)
      self.refs.new_mail.value = ''
      self.refs.new_pass.value = ''
      user.expanded = true
      self.users.unshift(user)
      self.update()
      api.emit('toast',{type:'info',title:'User created'})
    })
    .catch(e => {
      api.emit('toast',{type:'danger',title:'Error creating user',content:e.message})
      console.log('error creating user',e)
    })
  }.bind(this)

  self.on('mount', e => {
    api.service('users')
    .find({$sort:{email:1}})
    .then(r => {
      console.log('got users',r)
      self.users = r.data
      self.update()
    })
    .catch(e => {
      api.emit('toast',{type:'danger',title:'Error fetching users',content:e.message})
      console.log('error users',e)
    })
  })
});

