const riot = require('riot');
import {proptable} from '../components/audience.tag'
riot.tag2('mchart', '<div style="width:100%;height:300px;max-width:90vw;max-height:90vh;margin-bottom:2em" ref="chart_daily"></div> <div class="columns"> <div class="column"> <label class="label is-small">Visitors</label> <proptable if="{table_daily}" prop="visitors" ref="table_daily" tabledata="{table_daily}" sum_cols="1"></proptable> </div> <div class="column"> <label class="label is-small">Pageviews</label> <proptable if="{table_pageviews}" prop="pageviews" ref="table_pageviews" tabledata="{table_pageviews}" sum_cols="1"></proptable> </div> </div>', '', '', function(opts) {

  const self = this

	function getDailyVisitors(data) {
console.log('getting daily series',data)
    return data.filter(d => !!d.visitors).map(d => [d.day,d.visitors])
	}

	function getDailyPageviews(data) {
console.log('getting pageview series',data)
    return data.map(d => [d.day,d.pageviews])
	}

  function init_chart(all,profiled) {
    const visitors = getDailyVisitors(all),
          visitors_filtered = profiled ? getDailyVisitors(profiled) : false,
          pageviews = getDailyPageviews(all),
          pageviews_filtered = profiled ? getDailyPageviews(profiled) : false

    self.table_daily = profiled ? {
      columns: ["Date/Visitors","all","profiled"],
      percent_col:{label:"% prof/all",all:1,sel:2},
      rows: visitors.map((v,idx) => {
        const f = visitors_filtered[idx] && visitors_filtered[idx][1] || 0
        return [ v[0], v[1], f]
      })
    } : {
      columns: ["Date","Visitors"],
      rows: visitors.map((v,idx) => {
        return [ v[0], v[1] ]
      })
    }

    self.table_pageviews = profiled ? {
      columns: ["Date/Pageviews","all","profiled"],
      percent_col:{label:"% prof/all",all:1,sel:2},
      rows: pageviews.map((v,idx) => {
        const f = pageviews_filtered[idx] && pageviews_filtered[idx][1] || 0
        return [ v[0], v[1], f]
      })
    } : {
      columns: ["Date","Pageviews"],
      rows: pageviews.map((v,idx) => {
        return [ v[0], v[1] ]
      })
    }

	  self.chart_options = {
      xAxis: {
        type: 'time',
        axisLabel:{showMinLabel:false,showMaxLabel:false}
      },
      yAxis: [
				{type:'value',name:'Visitors',nameLocation:'end',nameTextStyle:{fontWeight:'bold'}},
				{type:'value',name:'Pageviews',nameLocation:'end', nameTextStyle:{fontWeight:'bold'}}
      ],
      tooltip: {
        trigger: 'axis'
      },
			legend: {
				data: ['All visitors', 'Selected visitors','All pageviews', 'Selected pageviews']
			},
			grid: [
				{x: '2%', y:'15%', width: '98%',height:'85%',containLabel:true}
			],
      series: [
        { type: "line", name: "All visitors", areaStyle:{}, data: visitors },
        { type: "line", name: "Selected visitors", areaStyle:{}, data: visitors_filtered },
        { type: "line", yAxisIndex:1, name: "All pageviews", data: pageviews },
        { type: "line", yAxisIndex:1, name: "Selected pageviews", data: pageviews_filtered },
      ]
    };

  }

  init_chart(opts.all,opts.profiled)

  self.on('mount',function() {
	  let chart = echarts.getInstanceByDom(self.refs['chart_daily'])
		if (!chart) chart = echarts.init(self.refs['chart_daily'],'light')
    chart.setOption(self.chart_options)
  })

});


import echarts from 'echarts/lib/echarts'
import 'echarts/lib/chart/bar'
import 'echarts/lib/chart/line'
import 'echarts/lib/chart/map'
import 'echarts/lib/component/geo'
import 'echarts/lib/component/visualMap'
import 'echarts/lib/component/tooltip'
riot.tag2('eki', '<div each="{market in [\'Slovenia\',\'Serbia\',\'Greece\',\'Czech\']}" class="card"> <header class="card-header {has-background-light:expanded}" onclick="{toggleExpand}"> <p class="card-header-title"> {market} </p> </header> <div class="card-content has-background-white-bis" style="margin-bottom:1rem"> <mchart market="{market}" all="{data[market]}" profiled="{data[market+\'-profiled\'] || null}"></mchart> </div> </div>', '', '', function(opts) {


  var self = this

  self.data = {
    "Slovenia": [
      {day:"2019-03-21", visitors:	765839, pageviews:	3526236},
      {day:"2019-03-22", visitors:	702228, pageviews:	3203728},
      {day:"2019-03-23", visitors:	607565, pageviews:	2515012},
      {day:"2019-03-24", visitors:	657430, pageviews:	2766279},
      {day:"2019-03-25", visitors:	795866, pageviews:	3753355},
      {day:"2019-03-26", visitors:	757223, pageviews:	3529464},
      {day:"2019-03-27", visitors:	771405, pageviews:	3651620},
      {day:"2019-03-28", visitors:	810638, pageviews:	3725380},
      {day:"2019-03-29", visitors:	765680, pageviews:	3451876},
      {day:"2019-03-30", visitors:	651439, pageviews:	2667628},
      {day:"2019-03-31", visitors:	657960, pageviews:	2677196}
    ],
    "Slovenia-profiled": [
      {day:"2019-03-21",visitors:	434786,pageviews:	2997647},
      {day:"2019-03-22",visitors:	402757,pageviews:	2727007},
      {day:"2019-03-23",visitors:	346594,pageviews:	2130398},
      {day:"2019-03-24",visitors:	374069,pageviews: 2349230},
      {day:"2019-03-25",visitors:	453222,pageviews:	3190990},
      {day:"2019-03-26",visitors:	432792,pageviews:	2994583},
      {day:"2019-03-27",visitors:	438512,pageviews:	3105133},
      {day:"2019-03-28",visitors:	455269,pageviews:	3147679},
      {day:"2019-03-29",visitors:	433528,pageviews:	2913729},
      {day:"2019-03-30",visitors:	361980,pageviews:	2231991},
      {day:"2019-03-31",visitors:	365433,pageviews:	2228710},
    ],
    "Serbia": [
      { "day" : "2019-03-21", "pageviews" : 19716678, "visitors" : 3295708 },
      { "day" : "2019-03-22", "pageviews" : 18794321, "visitors" : 3131001 },
      { "day" : "2019-03-23", "pageviews" : 17653193, "visitors" : 3042367 },
      { "day" : "2019-03-24", "pageviews" : 17435968, "visitors" : 3029081 },
      { "day" : "2019-03-25", "pageviews" : 19146550, "visitors" : 3200540 },
      { "day" : "2019-03-26", "pageviews" : 20916931, "visitors" : 3362369 },
      { "day" : "2019-03-27", "pageviews" : 20967330, "visitors" : 3359056 },
      { "day" : "2019-03-28", "pageviews" : 19686030, "visitors" : 3218163 },
      { "day" : "2019-03-29", "pageviews" : 18095107, "visitors" : 2996689 },
      { "day" : "2019-03-30", "pageviews" : 17133305, "visitors" : 2947566 },
      { "day" : "2019-03-31", "pageviews" : 17821200, "visitors" : 3071411 }
    ],
    "Greece": [
      {day:"2019-03-31", pageviews:	322336},
      {day:"2019-03-30", pageviews:	374878},
      {day:"2019-03-29", pageviews:	446808},
      {day:"2019-03-28", pageviews:	439524},
      {day:"2019-03-27", pageviews:	430404},
      {day:"2019-03-26", pageviews:	401203},
      {day:"2019-03-25", pageviews:	295251},
      {day:"2019-03-24", pageviews:	340699},
      {day:"2019-03-23", pageviews:	386211},
      {day:"2019-03-22", pageviews:	445510},
      {day:"2019-03-21", pageviews:	388395}
    ],
    "Czech": [
      {day:"2019-03-31", pageviews:	111829},
      {day:"2019-03-30", pageviews:	105443},
      {day:"2019-03-29", pageviews:	96582},
      {day:"2019-03-28", pageviews:	93397},
      {day:"2019-03-27", pageviews:	89984},
      {day:"2019-03-26", pageviews:	98149},
      {day:"2019-03-25", pageviews:	102510},
      {day:"2019-03-24", pageviews:	106522},
      {day:"2019-03-23", pageviews:	97256},
      {day:"2019-03-22", pageviews:	106706},
      {day:"2019-03-21", pageviews:	94641}
    ]
  }

});

