const riot = require('riot');
import route from 'riot-route';
riot.tag2('page-login', '<div class="wrapper"> <div class="modal is-active"> <div class="modal-background"></div> <div class="modal-content" style="width:300px;"> <form class="box" onsubmit="{login}"> <h3 class="title is-4">Login</h3> <div class="field"><p class="control has-icons-right"> <input ref="email" class="input" type="text" placeholder="Account"> <span class="icon is-small is-right"><i class="fa fa-user"></i></span> </p></div> <div class="field"><p class="control has-icons-right"> <input ref="password" class="input" type="password" placeholder="Password"> <span class="icon is-small is-right"><i class="fa fa-lock"></i></span> </p></div> <div class="field"><p class="control"> <button ref="submit" class="button is-primary">Login</button> </p></div> </form> </div> </div> </div>', '', '', function(opts) {
      const self = this

      this.login = function(e) {
        e.preventDefault()
        const submit = self.refs.submit
        submit.classList.add('is-loading')
        api.authenticate({
          strategy:'local',
          email:self.refs.email.value,
          password:self.refs.password.value
        })
        .then(r => {
          console.log('login ok')
          api.set('user',r.user)
          submit.classList.remove('is-loading')
          api.emit('toast',{type:'info',title:'Login successful'})
          route('home')
        })
        .catch(e => {
          submit.classList.remove('is-loading')
          api.emit('toast',{type:'danger',title:e.message})
        })
      }.bind(this)
});
