const riot = require('riot');
riot.tag2('page-header', '<nav class="navbar is-fixed-top" role="navigation" aria-label="main navigation"> <div class="navbar-brand"> <a class="navbar-item" href="#home"> <img src="https://ico.behaviour.exchange/wp-content/uploads/2018/01/bex-dark-blue_300.png" alt="BehaviourExchange Real-time Console"> </a> <span if="{parent.conf.mode==\'development\'}" class="navbar-item">DEVELOPMENT BUILD</span> <button role="button" class="navbar-burger button is-white" onclick="{toggle}" aria-label="menu" aria-expanded="false" style="width:auto; margin-right:.5rem;"> <div class="is-inline">#{opts.section}</div> <div class="icon is-inline"><i class="fa {fa-bars:!status,fa-times:status}"></i></div> </button> </div> <div class="navbar-menu {is-active:status}"> <div class="navbar-start"> <a each="{items}" class="navbar-item is-inline-block-touch {has-background-light:opts.section==path}" onclick="{close}" href="#{path}"> <span class="icon"><i class="fa fa-{icon}"></i></span> <span>{title}</span></a> </a> </div> <div class="navbar-end"> <a class="navbar-item is-inline-block-touch" onclick="{logout}"><span class="icon"><i class="fa fa-power-off"></i></span><span>Log Off</span></a> </div> </div> </nav>', 'page-header .navbar-menu,[data-is="page-header"] .navbar-menu{text-align:center} page-header .navbar-brand img,[data-is="page-header"] .navbar-brand img{max-height:2rem} page-header .navbar-brand .navbar-item,[data-is="page-header"] .navbar-brand .navbar-item{align-items:start}', '', function(opts) {
    const self = this

    self.selected = opts.section || 'home'

    document.querySelector('html').classList.add('has-navbar-fixed-top')

    self.items = [
      {path:'adstat',title:'HB Stats',icon:'list'},
      {path:'apistat',title:'API Stats',icon:'list'},
      {path:'properties',title:'Properties',icon:'list'},
      {path:'customers',title:'Customers',icon:'building'},
      {path:'reports',title:'Reports',icon:'file-alt'},
      {path:'audiences',title:'Audiences',icon:'chart-pie'},
      {path:'tagging',title:'Tagging',icon:'tags'},
      {path:'targeting',title:'Targeting',icon:'bullseye'},
      {path:'users',title:'Users',icon:'user'},
    ]
    self.status = false
    self.toggle = (e) => {
      e.preventDefault()
      self.status = !self.status
    }
    self.logout = (e) => {
      e.preventDefault()
      api.logout()
      .then(r => console.log('logged out'))
      .catch(e => console.log('error',e))
    }
    self.close = (e) => {
      self.status = false
    }

});
