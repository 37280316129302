import core from './core';
import * as riot from 'riot'
import './tags/app.tag'

/*
import api from './api.js'
riot.mixin({
  init: function (opts) {this.api = api}
})
window.api = api
*/

window.core = core;
window.riot = riot

api.service('audiences').timeout = 60000;
api.service('aggregate').timeout = 120000;
api.service('property').timeout = 30000;
api.service('campaigns').timeout = 30000;
api.service('stats-daily').timeout = 120000;
api.service('stats-profile').timeout = 120000;
api.service('stats-report').timeout = 120000;
api.service('sites').timeout = 120000;

riot.mount('app');





