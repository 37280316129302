// Initial declaration
const customerAPI = {},
      $select = ['_id','name','market','target_sites'];

// Function setup
customerAPI['GetOrSet'] = function (noError) {
	return new Promise((resolve, reject) => {
		// Get cached
		let output = api.get('customers');

		// Return if cached
		if (output)
			return resolve(output);

		// Get from database and cache
		api.service('customers').find({ query: { $limit: 2000, $select, $sort: { name: 1 } } })
			.then(customers => {
				// Cache
				api.set('customers', customers.data);

				// Resolve
				resolve(customers.data)
			})
			.catch(err => {
				if (!noError)
					reject(err);
				else
					resolve(null);
			});
	});
}
customerAPI['Get'] = function (noError) {
	return new Promise((resolve, reject) => {
		// Get from database and cache
		api.service('customers').find({ query: { $limit: 2000, $select, $sort: { name: 1 } } })
			.then(customers => {
				// Cache
				api.set('customers', customers.data);

				// Resolve
				resolve(customers.data)
			})
			.catch(err => {
				if (!noError)
					reject(err);
				else
					resolve(null);
			});
	});
}

//Listen to changes and update global var
api.service('customers').on('patched',data => {
console.log('customers patched')
  customerAPI['Get']()
  .then(r => api.emit('update-customer'))
})

api.service('customers').on('created',data => {
console.log('customer created')
  customerAPI['Get']()
  .then(r => api.emit('update-customer'))
})

api.service('customers').on('removed',data => {
console.log('customers removed')
  customerAPI['Get']()
  .then(r => api.emit('update-customer'))
})


// Export
export default customerAPI;
