// Initial declaration
const audienceAPI = {},
      $select = ['customer_id','name','filter','iab_filter','profile'];

// Function setup
audienceAPI['GetOrSet'] = function (noError) {
	return new Promise((resolve, reject) => {
		// Get cached
		let output = api.get('audiences');

		// Return if cached
		if (output)
			return resolve(output);

		// Get from database and cache
		api.service('audiences').find({ query: { $limit: 2000, $sort: { name: 1 } } })
			.then(audiences => {
				// Cache
				api.set('audiences', audiences.data);

				// Resolve
				resolve(audiences.data)
			})
			.catch(err => {
				if (!noError)
					reject(err);
				else
					resolve(null);
			});
	});
}
audienceAPI['Get'] = function (noError) {
	return new Promise((resolve, reject) => {
		// Get from database and cache
		api.service('audiences').find({ query: { $limit: 2000, $sort: { name: 1 } } })
			.then(audiences => {
				// Cache
				api.set('audiences', audiences.data);

				// Resolve
				resolve(audiences.data)
			})
			.catch(err => {
				if (!noError)
					reject(err);
				else
					resolve(null);
			});
	});
}

//listen to changes and update global object, emit 'audience-update' event
//Listen to changes and update global var
api.service('audiences').on('patched',data => {
console.log('audiences patched')
  //audienceAPI['Get']()
  api.emit('update-audience')
})

api.service('audiences').on('created',data => {
console.log('audience created')
  //audienceAPI['Get']()
  api.emit('update-audience')
})

api.service('audiences').on('removed',data => {
console.log('audience removed')
  //audienceAPI['Get']()
  api.emit('update-audience')
})

// Export
export default audienceAPI;
