const riot = require('riot');
import './audience.tag'
import ObjectId from 'bson-objectid';
riot.tag2('audiences', '<div class="level"> <div class="level-left"> <div class="level-item" style="display:block"> <h1 class="title is-5">Audience overview</h1> </div> </div> <div class="level-right"> <form onsubmit="{setSearchFilter(null,0)}" class="level-item"> <div class="field is-grouped is-flexwrap"> <div class="control" style="flex-shrink:1"> <div class="select is-small"> <select name="customer" ref="customer" onchange="{setCustomerFilter()}"> <option value="">Select customer</option> <option each="{customers}" riot-value="{_id}">{name}</option> </select> </div> </div> <div class="control has-icons-right" style="flex-shrink:1"> <input class="input is-info {is-warning:search_active} is-small" ref="new_name" oninput="{setSearchFilter(null,500)}" type="text" placeholder="Enter/Search audience name" riot-value="{filter.$or ? filter.$or[0].name.$regex : \'\'}"> <span class="icon is-small is-right" onclick="{clearSearch}" style="cursor:pointer;pointer-events:all"> <i class="fas {fa-times:!search_active,fa-spin:search_active,fa-spinner:search_active}"></i> </span> </div> <div class="control"> <span class="button is-info is-small" onclick="{createAudience}">Add <span class="is-hidden-mobile">Audience</span></span> </div> </div> </form> </div> </div> <nav class="buttons has-addons" style="justify-content:center"> <button class="button is-small" each="{letter in abc}" onclick="{setSearchFilter(\'^\'+letter,0)}">{letter}</button> </nav> <audience each="{audiences}" expand="{expanded}"></audience> <div class="level mt-3"> <div class="level-left"> <div class="level-item"> <button if="{!end_of_line}" class="button is-small is-info" onclick="{loadMore}">Load more</button> </div> </div> </div>', '', '', function(opts) {

  const self = this,
      user = api.get('user')

  self.search_active = false
  self.abc = 'abcčdefghijklmnoprqsštvuvxyzž'

  var filters = api.get('filters')
  filters['audiences'] = filters['audiences'] || {$sort:{name:1},$limit:30}
  self.filter = filters['audiences']

  self.audiences = []
  self.customers = []

  this.setCustomerFilter = function(val) {
    return function(e) {
      e.preventDefault()
      val = val || self.refs.customer.value
      if (!!val) self.refs.customer.value = self.filter.customer_id = val
      else delete self.filter.customer_id;
  console.log('filter',self.filter)
      e.preventUpdate = true
      loadAudiences()
    }
  }.bind(this)

  var search_string = '', search_tmout = false
  function setSearch(str,delay) {

    if (str) {
      self.filter.$or = [
        {name : {
          $regex: str,
          $options: 'i'
        }},
        {audience_id : {
          $regex: str,
          $options: 'i'
        }}
      ]
    } else delete self.filter.$or
console.log('filter',self.filter)
    if (search_tmout) clearTimeout(search_tmout)
    search_tmout = setTimeout(loadAudiences,delay)
  }
  this.setSearchFilter = function(val,delay) {
    return function(e) {
      e.preventDefault()
      val = val || self.refs.new_name.value
      self.refs.new_name.value = val
      setSearch(val,delay)

    }
  }.bind(this)
  this.clearSearch = function(e) {
    e.preventDefault()
    self.refs.new_name.value = ''
    setSearch('')
    e.preventUpdate = true
  }.bind(this)

  self.on('mount', () => {
	  core.customerAPI.GetOrSet()
	  .catch(err => api.emit('toast', { type:'danger', title:'Error fetching customer list', content:err.message }))
	  .then(customers => {
		  self.customers = customers;
      loadAudiences()
		})
  })

  let last_id = false

  api.on('update-customer',function(e) {
    self.customers = api.get('customers')
    self.update()
  })

  function loadAudiences(append=false) {
    self.search_active = true;
	  self.update()

	  var filter = Object.assign({},self.filter)
	  if (append && last_id) filter.name = Object.assign({},filter.name,{$gt: last_id})

    api.service('audiences').find({query:filter})
    .then(r => {
      console.log('got audiences',r)
      self.search_active = false;
      if (append && r.data.length>0) {
        self.audiences = self.audiences.concat(r.data)
      } else {
        self.audiences = r.data
      }
      last_id = r.data.length && r.data[r.data.length-1].name || false
      self.end_of_line = r.data.length < filter.$limit
      self.update()
    })
		.catch(err => {
			api.emit('toast', { type:'danger', title:'Error setting up audiences', content:err.message });
			console.error(err);
		});
  }

  this.loadMore = function(e) {
    e.preventDefault()
    e.preventUpdate = true
    loadAudiences(true)
  }.bind(this)

  this.createAudience = function(e) {
    e.preventDefault()
    const name = self.refs.new_name.value,
          customer_id = self.refs.customer.value

    if (!name) return api.emit('toast',{type:'danger',title:'Name cannot be emtpy'})
    if (!customer_id) return api.emit('toast',{type:'danger',title:'Please select a customer first.'})

    const customer = self.customers.find(c => c._id==customer_id)
    var audience_id = ObjectId()
    api.service('audiences')
    .create({
      _id: {_oid:audience_id},
      audience_id: audience_id,
      name: name,
      owner: customer.owner,
      customer_id: customer._id.toString(),
      sites: customer.sites
    })
    .then(audience => {
      self.refs.new_name.value = ''
      audience.expanded=true
      self.audiences.unshift(audience)
      self.update()
      console.log('created audience',audience)
      api.emit('toast',{type:'info',title:'Audience segment created'})
    })
    .catch(e => {
      api.emit('toast',{type:'danger',title:'Error creating audience',content:e.message})
      console.log('error creating audience',e)
    })
  }.bind(this)

});
