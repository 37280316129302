const riot = require('riot');
riot.tag2('measure', '<span if="{!expandable}" class="measure"> <span class="icon is-small has-text-grey"><i class="fa fa-genderless"></i></span> {m.name} </span> <span if="{expandable}" class="measure expandable" onclick="{toggleExpand}"> <span class="icon is-small"><i class="fa {fa-caret-right:!expanded,fa-caret-down:expanded}"></i></span> {m.name} </span> <ul if="{expanded}" class="is-list"> <virtual each="{c,cidx in m.children}" c="{c}"> <li data-is="measure" m="{c}" if="{c.active}"></li> </virtual> </ul>', '', '', function(opts) {
  const self = this
  self.m = opts.m
  self.expandable = !!opts.m.children.length
  self.expanded = false

  this.toggleExpand = function(e) {
    if (!self.expandable) return
    self.expanded = !self.expanded
    self.update()
  }.bind(this)
});

riot.tag2('properties', '<div class="level is-fat"> <div class="level-left"> <h1 class="title is-5 level-item">Properties overview</h1> </div> <div class="level-right"> <div class="level-item"> <button class="button {is-white:!filter,is-info:filter}" onclick="{toggleFilter}">Only active</button> </div> </div> </div> <div class="has-columns"> <div class="is-card message {is-light:!properties[pidx].active,is-info:properties[pidx].active}" each="{pidx,idx in property_order}"> <p class="message-header">{properties[pidx].name}</p> <ul if="{Object.keys(properties[pidx].measures).length}" class="message-body"> <li data-is="measure" each="{m,midx in properties[pidx].measures}" if="{!m.parent && m.active}" m="{m}"></li> </ul> </div> </div>', '', '', function(opts) {

  var self = this,
      properties = {},
      active_properties = {},
      filterProps = () => {
        if (self.filter) {
          self.property_order = [24,23,3,9,11,21]
        } else {
          self.property_order = [24,22,23,3,9,11,20,21,1,2,4,5,6,7,8,10,12,13,14,15,16,17]
        }
      }

  self.filter = true
  this.toggleFilter = function(e) {
    e.preventDefault()
    self.filter = !self.filter
    filterProps()
  }.bind(this)

  self.properties = api.get('properties')
  self.property_order = [24,23,3,9,11,21]
  self.on('update', e => {
    self.properties = api.get('properties')
  })

});

