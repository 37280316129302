const riot = require('riot');
import './report.tag'
riot.tag2('reports', '<div class="level"> <div class="level-left"> <div class="level-item" style="display:block"> <h1 class="title is-5">Reports overview</h1> </div> </div> <div class="level-right"> <form onsubmit="{setSearchFilter(null,0)}" class="level-item"> <div class="field is-grouped is-flexwrap"> <div class="control" style="flex-shrink:1"> <div class="select is-small"> <select name="customer" ref="customer" onchange="{setCustomerFilter()}"> <option value="">Select customer</option> <option each="{customers}" riot-value="{_id}">{name}</option> </select> </div> </div> <div class="control has-icons-right" style="flex-shrink:1"> <input class="input is-info {is-warning:search_active} is-small" ref="new_name" oninput="{setSearchFilter(null,500)}" type="text" placeholder="Enter/Search report name" riot-value="{filter.name ? filter.name.$regex : \'\'}"> <span class="icon is-small is-right" onclick="{clearSearch}" style="cursor:pointer;pointer-events:all"> <i class="fas {fa-times:!search_active,fa-spin:search_active,fa-spinner:search_active}"></i> </span> </div> <div class="control"> <span class="button is-info is-small" onclick="{createReport}">Add <span class="is-hidden-mobile">Report</span></span> </div> </div> </form> </div> </div> <nav class="buttons has-addons" style="justify-content:center"> <button class="button is-small" each="{letter in abc}" onclick="{setSearchFilter(\'^\'+letter,0)}">{letter}</button> </nav> <virtual data-is="report" each="{reports}" expand="{expanded}"></virtual> <div class="level mt-3"> <div class="level-left"> <div class="level-item"> <button if="{!end_of_line}" class="button is-small is-info" onclick="{loadMore}">Load more</button> </div> </div> </div>', '', '', function(opts) {

  var self = this,
      cid = opts.id,
      fs = require('fs'),
      filters = api.get('filters')

  self.search_active = false
  self.abc = 'abcčdefghijklmnoprqsštvuvxyzž'
  filters['reports'] = filters['reports'] || {$sort:{name:1},$limit:30}
  self.filter = filters['reports']
  self.reports = []
  self.customers = []

  this.setCustomerFilter = function(val) {
    return function(e) {
      e.preventDefault()
      val = val || self.refs.customer.value
      if (!!val) self.filter.customer_id = val
      else delete self.filter.customer_id;
  console.log('filter',self.filter)
      e.preventUpdate = true
      loadReports()
    }
  }.bind(this)

  var search_string = '', search_tmout = false
  function setSearch(str,delay) {

    if (str) {
      self.filter.name = {
        $regex: str,
        $options: 'i'
      }
    } else delete self.filter.name
console.log('filter',self.filter)
    if (search_tmout) clearTimeout(search_tmout)
    search_tmout = setTimeout(loadReports,delay)
  }
  this.setSearchFilter = function(val,delay) {
    return function(e) {
      e.preventDefault()
      val = val || self.refs.new_name.value
      self.refs.new_name.value = val
      setSearch(val,delay)

    }
  }.bind(this)
  this.clearSearch = function(e) {
    e.preventDefault()
    self.refs.new_name.value = ''
    setSearch('')
    e.preventUpdate = true
  }.bind(this)

  this.createReport = function(e) {
    e.preventDefault()
    let name = self.refs.new_name.value,
        customer_id = self.refs.customer.value,
        user = api.get('user')

    if (!customer_id) return api.emit('toast',{type:'danger',title:'Select a customer first'})
    if (!name) return api.emit('toast',{type:'danger',title:'Name cannot be emtpy'})

    const template = fs.readFileSync('./app/assets/report.md','utf8')
console.log('template',template)

    let customer = self.customers.find(c => c._id==customer_id)

    api.service('reports')
    .create({
      name: name,
      owner:customer.owner,
      customer_id:customer._id,
      sites:customer.sites,
      detail_segments:[],
      other_segments:[],
      template:template,
      rendered:'<h4 class="is-title is-6"> not rendered yet </h4>'
    })
    .then(report => {
      console.log('created report',report)
      self.refs.new_name.value = ''
      report.expanded=true
      self.reports.unshift(report)
      self.update()
      api.emit('toast',{type:'info',title:'Report created'})
    })
    .catch(e => {
      api.emit('toast',{type:'danger',title:'Error creating report',content:e.message})
      console.log('error creating report',e)
    })
  }.bind(this)

  self.on('mount',() => {
    loadReports()
  })

  let last_id = false
  function loadReports(append=false) {
    self.search_active = true;
	  self.update()

	  var filter = Object.assign({},self.filter)
	  if (append && last_id) filter.name = Object.assign({},filter.name,{$gt: last_id})

	  core.customerAPI.GetOrSet()
	  .catch(err => api.emit('toast', { type:'danger', title:'Error fetching customer list', content:err.message }))
	  .then(customers => {
		  self.customers = customers;
      return api.service('reports').find({query:filter})
    })
    .then(r => {
      console.log('got reports',r)
      self.search_active = false;
      if (append && r.data.length>0) {
        self.reports = self.reports.concat(r.data)
      } else {
        self.reports = r.data
      }
      last_id = r.data.length && r.data[r.data.length-1].name || false
      self.end_of_line = r.data.length < filter.$limit
      self.update()
    })
    .catch(e => {
      api.emit('toast',{type:'danger',title:'Error fetching reports',content:e.message})
      console.log('error reports',e)
    })
  }

  this.loadMore = function(e) {
    e.preventDefault()
    e.preventUpdate = true
    loadReports(true)
  }.bind(this)

});

